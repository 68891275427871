import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import MainLayout from 'components/layouts/Main'
import Tabs from 'components/common/Tabs'
import Instructions from 'components/custom/Instructions'
import DosAndDonts from 'components/custom/DosAndDonts'

import appContent from 'content/app.json'
import withAuth from 'lib/utils/withAuth'

type InsepctionPageQueryProps = {
  data: {
    mdx: {
      body: string
      id: string
      frontmatter: {
        title: string
        dosAndDonts: string[]
        defaultPageTitleText: string
        instructionsTabText: string
        dosAndDontsTabText: string
        dosAndDontsHeaderText: string
      }
    }
  }
}

type InspectionPageTemplateProps = PageProps & InsepctionPageQueryProps

const InspectionPageTemplate: React.FC<InspectionPageTemplateProps> = ({ location, data }) => {
  const {
    title,
    defaultPageTitleText,
    dosAndDonts,
    dosAndDontsHeaderText,
    dosAndDontsTabText,
    instructionsTabText,
  } = data.mdx.frontmatter
  const [pageTitle, setPageTitle] = React.useState<string>(defaultPageTitleText)

  const headers = [instructionsTabText, dosAndDontsTabText]
  const contents = [
    <Instructions key={data.mdx.id}>{data.mdx.body}</Instructions>,
    <DosAndDonts key={title} header={dosAndDontsHeaderText} list={dosAndDonts || []} />,
  ]

  const handlePageTitle = React.useCallback(
    (key: number) => {
      setPageTitle(key === 0 ? defaultPageTitleText : title)
    },
    [defaultPageTitleText, title]
  )

  return (
    <MainLayout
      backgroundColor={appContent.backgroundColor}
      backgroundImage={appContent.backgroundImage}
      showTabBar={!!appContent.tabBar}
      currentPath={location.pathname}
      pageTitle={pageTitle}
      showBackButton
      content={<Tabs headers={headers} contents={contents} onSwitchTab={handlePageTitle} />}
    />
  )
}

export const query = graphql`
  query InspectionPageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        dosAndDonts
        defaultPageTitleText
        instructionsTabText
        dosAndDontsTabText
        dosAndDontsHeaderText
      }
    }
  }
`

export default withAuth(InspectionPageTemplate)
