import * as React from 'react'
import styled, { css } from 'styled-components'

import Container from './Container'

const TabsContainer = styled(Container)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const TabsSelectionContainer = styled(Container)`
  background-color: #232323;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.05em;
  padding: 6px 8px 7px 8px;
  border-radius: 29px;
  margin-bottom: 19px;
  width: 320px;
`

const TabHeaderItem = styled.a<{ isActive?: boolean }>`
  padding: 6px 30px 15px;
  max-height: 42px;
  cursor: pointer;

  ${(props) => {
    if (props.isActive) {
      return css`
        background-color: #a98d40;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        border-radius: 29px;
      `
    }
  }}
`

const TabsContentContainer = styled(Container)``

type Props = {
  headers?: string[]
  contents?: React.ReactNode[]
  onSwitchTab: (tabId: number) => void
}

const Tabs: React.FC<Props> = ({ headers, contents, onSwitchTab }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0)

  const handleSwitchTab = React.useCallback(
    (tabKey: number) => {
      onSwitchTab(tabKey)
      setSelectedTabIndex(tabKey)
    },
    [onSwitchTab]
  )

  return (
    <TabsContainer>
      <TabsSelectionContainer>
        {headers?.map((header, key: number) => {
          return (
            <TabHeaderItem
              key={key}
              isActive={key === selectedTabIndex}
              onClick={() => handleSwitchTab(key)}
            >
              {header}
            </TabHeaderItem>
          )
        })}
      </TabsSelectionContainer>
      <TabsContentContainer>{contents && contents[selectedTabIndex]}</TabsContentContainer>
    </TabsContainer>
  )
}

export default Tabs
