import React, { useState } from 'react'
import { MDXProvider, MDXProviderComponentsProp } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'

import colors from 'lib/utils/colors'
import Container from 'components/common/Container'
import InstructionsProvider from 'components/providers/InstructionsProvider'

const InstructionRootContainer = styled(Container)`
  width: inherit;
  max-width: 768px;
  height: 100%;
  border-radius: 24px;
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  color: ${colors.Black};
  padding-bottom: 50px;
`

const CustomH1 = styled.h1`
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.02em;
`

const CustomH2 = styled.h2`
  color: ${colors.Brass};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin-top: 8px;
`

const CustomUL = styled.ul`
  margin-left: 16px;
  margin-bottom: 0px;
  list-style: disc;
`

const CustomLI = styled.li`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;

  ::marker {
    color: ${colors.Brass};
    width: 6px;
    height: 6px;
  }
`

type InstructionsProps = {
  children: string
}

const components: MDXProviderComponentsProp = {
  h1: CustomH1,
  h2: CustomH2,
  ul: CustomUL,
  li: CustomLI,
}

export const Instructions: React.FC<InstructionsProps> = ({ children }) => {
  const [openedExplanations, setOpenedExplanations] = useState<string[]>([])

  const handleToggle = (reference: string) => {
    const foundIndex = openedExplanations.indexOf(reference)

    setOpenedExplanations((current) =>
      foundIndex > -1 ? current.filter((item) => item !== reference) : [...current, reference]
    )
  }

  return (
    <InstructionsProvider value={{ onToggle: handleToggle, openedExplanations }}>
      <InstructionRootContainer>
        <MDXProvider components={components}>
          <MDXRenderer>{children}</MDXRenderer>
        </MDXProvider>
      </InstructionRootContainer>
    </InstructionsProvider>
  )
}

export default Instructions
