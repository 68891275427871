import React, { useMemo } from 'react'
import styled from 'styled-components'

import Icon from 'components/vectors/Icon'
import Container from 'components/common/Container'
import DosAndDontsItem from './DosAndDontsItem'

const ListHeaderContainer = styled(Container)`
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: center;
  justify-content: space-between;
  align-items: space-between;
  padding-bottom: 21px;
  width: 100%;
`

const DosAndDontsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

export enum ItemType {
  do = '+',
  dont = '-',
  note = 'n',
}

export type Item = {
  type: ItemType
  text: string
}

type DosAndDontsProps = {
  header: string
  list: string[]
}

export const DosAndDonts: React.FC<DosAndDontsProps> = ({ header, list }) => {
  const listItems: Item[] = useMemo(
    () =>
      list
        .map((item) => {
          const type = item.trim().charAt(0) as ItemType
          const text = item.substring(1, item.length)

          return {
            type,
            text,
          }
        })
        .filter((item) => ['+', '-', 'n'].includes(item.type)),
    [list]
  )

  return (
    <DosAndDontsContainer>
      <ListHeaderContainer>
        <Icon
          name='circle-check'
          iconStyle={{
            fill: '#57BD62',
            stroke: '#ffffff',
          }}
        />
        {header}
        <Icon
          name='circle-cross'
          iconStyle={{
            fill: '#B14B44',
            stroke: '#ffffff',
          }}
        />
      </ListHeaderContainer>

      {listItems.map((item, i) => {
        return <DosAndDontsItem key={i} {...item} />
      })}
    </DosAndDontsContainer>
  )
}

export default DosAndDonts
