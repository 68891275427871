import React from 'react'
import styled from 'styled-components'

import Icon from 'components/vectors/Icon'
import Container from 'components/common/Container'
import { Item, ItemType } from 'components/custom/DosAndDonts'
import colors from 'lib/utils/colors'

const ItemContainer = styled(Container)<{ type: ItemType }>`
  background-color: white;
  border: ${(props) => `2px solid ${props.type === ItemType.do ? '#57bd62' : '#B14B44'}`};
  border-radius: 12px;
  color: #313131;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`

const IconContainer = styled(Container)`
  margin-right: 15px;
  padding-top: 8px;
`

const NoteContainer = styled(Container)`
  padding: 12px 16px;
  margin: 0px;
  margin-bottom: 16px;
  background-color: ${colors.Brass};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.03em;
`

const DosAndDontsItem: React.FC<Item> = ({ text, type }) => {
  if (type === ItemType.note) {
    return <NoteContainer>{text}</NoteContainer>
  }

  return (
    <ItemContainer type={type}>
      <IconContainer>
        <Icon name={type === ItemType.do ? 'check' : 'cross'} />
      </IconContainer>
      {text}
    </ItemContainer>
  )
}

export default DosAndDontsItem
